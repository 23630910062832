import { loadRemoteEntry } from '@angular-architects/module-federation';
import { environment } from './environments/environment';

Promise.all([
    loadRemoteEntry(environment.GCU_FOREX_BASE_URL, 'custodyForex'),
    loadRemoteEntry(environment.GCU_ECASH_BASE_URL, 'custodyEcash'),
    loadRemoteEntry(environment.GCU_HABILITATION_BASE_URL, 'custodyHabilitation'),
    loadRemoteEntry(environment.GCU_SUBSCRIPTION_BASE_URL, 'custodySubscription'),
    loadRemoteEntry(environment.GCU_FASTNET_BASE_URL, 'custodyFastnet'),
    loadRemoteEntry(environment.GCU_CORPORATE_ACTIONS_BASE_URL, 'custodyCorporateAction'),
    loadRemoteEntry(environment.GCU_ESETTLEMENT_BASE_URL, 'custodyEsettlement'),
])
    .catch((err) => console.error('Error loading remote entries', err))
    .then(() => import('./bootstrap'))
    .catch((err) => console.error(err));
